import {Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {Task} from '../../../models/task';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TaskAnswer} from '../../../models/task-answer';
// lodash
import {Combination} from '../../../models/combination';
import {StaticRange} from '../../../models/chart/static-range';
import {LessonService} from '../../../modules/training/services/lesson.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTabGroup} from '@angular/material/tabs';
import {Card} from '../../../models/card';
import {DefaultDataService} from '../../../services/default-data.service';
import {UserHotKey} from '../../../models/user/user-hotkey';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-hand-replay-dialog',
  templateUrl: './hand-replay-dialog.component.html',
  styleUrls: ['./hand-replay-dialog.component.scss']
})
export class HandReplayDialogComponent implements OnInit {

  hotKey: UserHotKey;

  task: Task;

  tasks: Array<Task>;
  rangeId: number;
  secondAction: number;
  secondActionFactor: number;

  formGroup: FormGroup;
  @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

  isClickReplay: boolean;
  range: StaticRange;
  signedCombination: Combination;
  isPrev: boolean;
  isNext: boolean;
  isChartTabOpen = false;
  indexHistoryItem: number;
  lengthHistoryItems: number;

  tabs = [
    {id: 0, title: 'table'},
    {id: 1, title: 'chart'}
  ];

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.code === this.hotKey.keyCode) {
      event.preventDefault();
      this.onCloseButtonClick();
    }
  }

  constructor(public dialogRef: MatDialogRef<HandReplayDialogComponent>, private lessonService: LessonService,
              private formBuilder: FormBuilder, private defaultDataService: DefaultDataService,
              private userService: UserService,
              @Inject(MAT_DIALOG_DATA) public data: {tasks: Array<Task>, lengthHistoryItems: number,
                indexHistoryItem: number, isChartTabOpen: boolean, isClickReplay: boolean}) {

    this.isChartTabOpen = data.isChartTabOpen;
    this.isClickReplay = data.isClickReplay;
    this.lengthHistoryItems = data.lengthHistoryItems;
    this.tasks = data.tasks;

    this.task = data.tasks[data.indexHistoryItem];
    this.rangeId = this.task.rangeId;
    this.secondAction = this.task.secondAction;
    this.secondActionFactor = this.task.secondActionFactor;
    this.signedCombination = this.generateCombination(this.task.hero.card1, this.task.hero.card2);
    this.indexHistoryItem = data.indexHistoryItem;

    this.isPrev = this.indexHistoryItem === 0;
    this.isNext = this.indexHistoryItem === (this.lengthHistoryItems - 1);

    this.lessonService.getRangeById(this.rangeId).subscribe(
      (newRange: StaticRange) => {
        this.range = newRange;
      });
  }

  ngOnInit() {
    this.hotKey = this.userService.getUser().hotKeys.find((hotKey: UserHotKey) => hotKey.action === 'closeHint');
    this.initFormGroup();
  }

  getCorrectAnswer(): {title: string, amount: number} {
    if (!this.task) {
      return {title: 'string', amount: 0};
    }
    let correctAnswer = this.task.answers.find((answer: TaskAnswer) => {
      return answer.isCorrect === true;
    });
    if (!correctAnswer) {
      correctAnswer = this.task.alternativeAnswers.find((answer: TaskAnswer) => {
        return answer.isCorrect === true;
      });
    }
    const newTitle = !correctAnswer.amount && correctAnswer.factor ?
      `${correctAnswer.title} x${correctAnswer.factor.toString()}`
      : correctAnswer.title;
    return {title: newTitle, amount: correctAnswer.amount};
  }

  initFormGroup() {
    let selectTab = this.tabs[0];
    if (!this.isClickReplay || this.isChartTabOpen) {
      selectTab = this.tabs[1];
      this.tabGroup.selectedIndex = 1;
    }

    this.formGroup = this.formBuilder.group({
      tabCtrl: [selectTab, Validators.required],
    });

  }

  onChangedTab(event) {
    this.tabGroup.selectedIndex = event.id;
  }

  openPrevReplay() {

    if (this.indexHistoryItem > 0) {
      this.indexHistoryItem = this.indexHistoryItem - 1;
    }

    this.isPrev = this.indexHistoryItem === 0;
    this.isNext = false;
    this.selectTask();
  }

  openNextReplay() {

    if (this.indexHistoryItem !== this.lengthHistoryItems - 1) {
      this.indexHistoryItem = this.indexHistoryItem + 1;
    }

    this.isNext = this.indexHistoryItem === (this.lengthHistoryItems - 1);
    this.isPrev = false;
    this.selectTask();


  }

  selectTask() {
    this.task = this.tasks[this.indexHistoryItem];
    this.rangeId = this.task.rangeId;
    this.secondAction = this.task.secondAction;
    this.signedCombination = this.generateCombination(this.task.hero.card1, this.task.hero.card2);
    this.lessonService.getRangeById(this.rangeId).subscribe(
      (newRange: StaticRange) => {
        this.range = newRange;
      });
  }

  generateCombination(card1: Card, card2: Card): Combination {
    let postfix = '';
    if (card1.dignity !== card2.dignity) {
      postfix = card1.suit === card2.suit ? 's' : 'o';
    }
    const title = card1.dignity.toLocaleUpperCase() + card2.dignity.toLocaleUpperCase() + postfix;
    const title2 = card2.dignity.toLocaleUpperCase() + card1.dignity.toLocaleUpperCase() + postfix;
    return this.defaultDataService.getCombinationByTitle(title, title2);
  }

  onCloseButtonClick() {
    this.dialogRef.close(null);
  }

}
