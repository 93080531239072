import {Component, HostBinding, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-long-menu',
  templateUrl: './long-menu.component.html',
  styleUrls: ['./long-menu.component.scss']
})
export class LongMenuComponent implements OnInit {

  get isAdmin() {
    return this.userService.isAdmin();
  }

  get isPartner() {
    return this.userService.isPartner();
  }

  get isCoach() {
    return this.userService.isCoach();
  }

  @HostBinding('class.app-long-menu') isDefaultClassUsed = true;

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

}
