import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Answer} from '../../../modules/charts/models/answer';
import {SplitAnswer} from '../../../modules/charts/models/split-answer';
import {SnackbarService} from '../../services/snackbar.service';

@Component({
  selector: 'app-split-answer-creating-dialog',
  templateUrl: './split-answer-creating-dialog.component.html',
  styleUrls: ['./split-answer-creating-dialog.component.scss']
})
export class SplitAnswerCreatingDialogComponent implements OnInit {

  answers: Answer[];

  splitParts: Array<{answer?: Answer, weight?: number}> = [{}, {}];

  constructor(public dialogRef: MatDialogRef<SplitAnswerCreatingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Answer[], private snackBarService: SnackbarService) {
    this.answers = data;
  }

  ngOnInit() {
  }

  onCloseButtonClick() {
    this.dialogRef.close(null);
  }

  onFinishButtonClick() {

    if (this.checkValidity()) {
      let answerValue = '';
      let newTitle = '';
      this.splitParts.forEach((part, index) => {
        if (index === 0) {
          answerValue += part.answer.value + '(' + part.weight + ')-';
          newTitle += `${part.weight.toString()}% ${part.answer.title} `;
        }
        if (index !== 0 && index !== this.splitParts.length - 1) {
          answerValue += `${part.answer.value}(${this.splitParts[index - 1].weight + part.weight})-`;
          newTitle += `${part.weight.toString()}% ${part.answer.title} `;
        }
        if (index === this.splitParts.length - 1) {
          answerValue += part.answer.value;
          newTitle += `${part.weight.toString()}% ${part.answer.title} `;
        }
      });

      const splitAnswer: SplitAnswer = {
        parts: this.splitParts,
        isRandom: 'y',
        value: answerValue,
        title: newTitle
      };

      this.dialogRef.close(splitAnswer);
    } else {
      this.snackBarService.showMessage('Please fill correctly');
    }
  }

  checkValidity(): boolean {
    let sum = 0;
    this.splitParts.forEach((part) => {
      if (!part.answer || !part.weight) {
        return false;
      }
      sum += part.weight;
    });

    return sum === 100;
  }

  deleteRow(index: number) {
    this.splitParts.splice(index, 1);
  }

  onAddButtonClick() {
    this.splitParts.push({});
  }

}
