import { Injectable } from '@angular/core';
import {Chart} from '../../models/chart/chart';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  groupChartByAction(charts: Chart[]): ChartGroup[] {
    const groupsMap: Map<string, ChartGroup> = charts.reduce((resultMap, chart) => {

      const actionTitle: string = chart.action ?
        ' ' + `${chart.action.title} ${chart.dynamicRaises && chart.dynamicRaises.length ? chart.dynamicRaises[0].amount + 'bb' : ''}`
        : `${chart.dynamicRaises.length ? chart.dynamicRaises[0].amount + 'bb' : ''}`;
      const key: string = chart.position.title + actionTitle;

      if (!resultMap.has(key)) {

        const title: string = key;

        const sortPriority: number = this.getSortPriority(chart.position.id);

        resultMap.set(key, {charts: [], title, sortPriority});
      }

      resultMap.get(key).charts.push(chart);

      return resultMap;
    }, new Map());

    const groups: ChartGroup[] = Array.from(groupsMap.values());

    groups.sort((group1: ChartGroup, group2: ChartGroup): number => {
      return group2.sortPriority - group1.sortPriority;
    });

    groups.forEach((group: ChartGroup) => {

      group.hands = group.charts.reduce((sum: number, chart: Chart) => {
        return sum + chart.hands;
      }, 0);
      const totalMistakes = group.charts.reduce((sum: number, chart: Chart) => {
        return sum + chart.mistakes;
      }, 0);
      group.winRate = group.hands > 0 ? 100 - Math.round(totalMistakes * 100 / group.hands) : 0;
    });
    return groups;
  }

  getSortPriority(positionId: number): number {
    switch (positionId) {
      case 0:
        return 5;
      case 1:
        return 2;
      case 2:
        return 8;
    }
  }

  groupLesson(charts: Chart[]): LessonGroup[] {
    const groupsMap: Map<string, LessonGroup> = charts.reduce((resultMap, chart) => {
      const key: string = chart.position.title;

      if (!resultMap.has(key)) {

        const title: string = key;

        const sortPriority: number = this.getSortPriority(chart.position.id);

        resultMap.set(key, {fishCharts: [], regCharts: [], title, sortPriority});
      }

      if (chart.opponentsType.id === 1) {
        resultMap.get(key).fishCharts.push(chart);

      } else if (chart.opponentsType.id === 0) {

        resultMap.get(key).regCharts.push(chart);
      }

      return resultMap;
    }, new Map());

    const groups: LessonGroup[] = Array.from(groupsMap.values());

    groups.sort((group1: LessonGroup, group2: LessonGroup): number => {
      return group2.sortPriority - group1.sortPriority;
    });

    return groups;
  }

  isToolTipAvailable(combination: string): boolean {
    const parts = combination.split(',');
    if (!parts[1]) {
      return false;
    }
    return parts[1] === 'n';
  }

  getToolTipForCombination(combination: string): string {
    const parts = combination.split(',');
    if (!parts[1]) {
      return '';
    }

    if (parts[1] === 'n') {
      return '';
    } else {
      const combinationParts = [];
      let toolTip = '';
      const answers = parts[2].split('-');
      answers.forEach((answer: string, i: number) => {
        let symbol;
        let answerWidth;
        if (i === 0) {
          symbol = answer[2] === '(' ? answer.substr(0, 2) : answer[0];
          const helpIndex = answer.indexOf(')');
          answerWidth = +answer.substr(symbol.length + 1, helpIndex - symbol.length - 1);
        }
        if (i !== 0 && i !== answers.length - 1) {
          symbol = answer[2] === '(' ? answer.substr(0, 2) : answer[0];
          const helpIndex = answer.indexOf(')');
          answerWidth = +answer.substr(symbol.length + 1, helpIndex - symbol.length - 1)
            - combinationParts[combinationParts.length - 1];
        }
        if (i === answers.length - 1) {
          answerWidth = 100 - combinationParts.reduce((sum, combinationPart) => sum + combinationPart, 0);
        }

        combinationParts.push(answerWidth);
        toolTip += answerWidth.toString();
        if (i !== answers.length - 1) {
          toolTip += '/';
        }
      });
      return toolTip;
    }
  }

}

export interface ChartGroup {
  charts: Chart[];
  title: string;
  sortPriority: number;
  hands?: number;
  winRate?: number;
}

export interface LessonGroup {
  fishCharts: Chart[];
  regCharts: Chart[];

  title: string;
  sortPriority: number;
  hands?: number;
  winRate?: number;
}
