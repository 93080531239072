import {Pipe, PipeTransform} from '@angular/core';
import {UserService} from '../../services/user.service';

@Pipe({
  name: 'stackTransform'
})
export class StackToBbPipe implements PipeTransform {

  constructor(private userService: UserService) {
  }

  transform(stack: number): string {

    const viewInfo = this.userService.getViewInfo();

    if (viewInfo.isStackInBB) {
      stack = round(stack, viewInfo.roundTo);
      return stack.toString() + 'bb';
    } else {
      const bigBlind = viewInfo.limit / 100;
      const symbol = viewInfo.symbol === undefined ? '$' : viewInfo.symbol;
      stack = round((bigBlind * stack), 2);
      const calculatedStack = stack % 1 !== 0 ? stack.toFixed(2) : stack.toString();
      return symbol + calculatedStack;
    }

  }

}

function round(value, decimals) {
  return Number(Math.round(+(value + 'e' + decimals)) + 'e-' + decimals);
}
