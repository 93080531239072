import {Component, HostBinding, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {

  get isAdmin() {
    return this.userService.isAdmin();
  }

  get isPartner() {
    return this.userService.isPartner();
  }

  get isCoach() {
    return this.userService.isCoach();
  }

    @HostBinding('class.app-mobile-menu') isDefaultClassUsed = true;

    constructor(private userService: UserService) {
    }

    ngOnInit() {
    }

}
