import {Component, Input, OnInit} from '@angular/core';
import {TaskAnswer} from '../../../models/task-answer';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {

  @Input() action: TaskAnswer;

  constructor() {
  }

  ngOnInit() {
  }


}
