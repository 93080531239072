import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AppService} from '../../services/app.service';
import {UserService} from '../../services/user.service';
import {User} from '../../models/user/user';
import {SnackbarService} from '../../shared/services/snackbar.service';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService, GoogleLoginProvider } from 'angularx-social-login';
import {from} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  invite = '';

  isInstagram: boolean;

  @ViewChild('headerTitleTemplate', {static: true}) headerTitleTemplate: TemplateRef<any>;

  constructor(private appService: AppService, private userService: UserService, private route: ActivatedRoute,
              private router: Router, private snackbarService: SnackbarService, private authService: AuthService) {
  }

  ngOnInit() {
    this.isInstagram = navigator.userAgent.includes('Instagram');
    this.appService.headerTitleTemplate = this.headerTitleTemplate;
    const ref = this.route.snapshot.queryParamMap.get('ref');
    const buy = this.route.snapshot.queryParamMap.get('buy');
    const autoLogin = this.route.snapshot.queryParamMap.get('autoLogin');
    localStorage.setItem('ref', ref);
    if (buy) {
      localStorage.setItem('buy', buy);
      this.onLoginButtonClick();
    }
    if (!buy && autoLogin) {
      this.onLoginButtonClick();
    }
  }

  ngOnDestroy(): void {
    this.appService.headerTitleTemplate = null;
  }

  onLoginButtonClick() {
    const ref = localStorage.getItem('ref');
    from(this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)).pipe(
      switchMap((userData: any) => {
        return this.userService.checkAuth(userData.idToken, ref);
    }),
    ).subscribe((data: {isExist: boolean, user: User, token: string}) => {
      if (data.isExist) {
        this.appService.isAuthenticated.next(true);
        localStorage.setItem('token', data.token);
        this.router.navigate(['settings']);
      } else {
        this.snackbarService.showMessage('Wrong neighborhood bro');
      }
    });
  }

  onCopyButtonClick() {

    const selBox = document.createElement('textarea');
    selBox.value = 'https://cash.preflophero.com/login?ref=inst';
    document.body.appendChild(selBox);
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackbarService.showMessage('Link copied');

  }

}
