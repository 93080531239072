import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'productName'
})
export class ProductNamePipe implements PipeTransform {

  transform(product: string): string {

    switch (product) {
      case 'ph-cash-sub-1':
        return '1 month';
      case 'ph-cash-sub-3':
        return '3 months';
      case 'ph-cash-sub-12':
        return '12 months';
      case 'ph-cash-chart-1':
        return 'GTO pack';
      case 'dyrdom-pack':
        return 'dyrdom1 pack';
      default:
        return 'unknown';
    }

  }
}
