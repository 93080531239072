import {Injectable} from '@angular/core';
import {Lesson} from '../models/lesson';
import {Player} from '../models/player';
import {Task} from '../models/task';
import {TaskAnswer} from '../models/task-answer';
import {Card} from '../models/card';
import {Chart} from '../models/chart/chart';
import {DefaultDataService} from './default-data.service';
import {Answer} from '../modules/charts/models/answer';
import {User} from '../models/user/user';
import {StaticRange} from '../models/chart/static-range';
import {UserSettings} from '../models/user/user-settings';
import {ChartPack} from '../models/chart/chart-pack';
import {MistakesInfo} from '../models/stats/mistakes-info';
import {MixedChart} from '../models/chart/mixed-chart';
import {UserTableInfo} from '../models/user/user-table-info';
import {UsersSummary} from '../models/user/users-summary';
import {PurchaseTableInfo} from '../modules/admin/models/purchaseTableInfo';
import {Purchase} from '../modules/admin/models/purchase';
import {PurchaseSummary} from '../modules/admin/models/purchaseSummary';
import {Challenge} from '../models/challenge/challenge';
import {ChallengeLeaderBoard} from '../models/challenge/challenge-leader-board';
import {ChallengePlayer} from '../models/challenge/challenge-player';
import {DashboardUsers} from '../modules/admin/models/dashboardUsers';
import {DashboardCoaches} from '../modules/admin/models/dashboardCoaches';
import {DashboardCashFlow} from '../modules/admin/models/dashboardCashFlow';
import {DashboardPartners} from '../modules/admin/models/dashboardPartners';
import {CoachPayment} from '../modules/admin/models/coachPayment';
import {PartnerPayment} from '../modules/admin/models/partnerPayment';
import {PartnerReferral} from '../modules/admin/models/partnerReferral';
import {CashChart} from '../models/chart/cash-chart';
import {HandsRange} from '../models/hands-range';
import {Combination} from '../models/combination';
import {DashboardCommission} from '../models/dashboardCommission';
import {ChartPosition} from '../models/chart/chart-position';
import {DashboardVisitUser} from '../modules/admin/models/dashboard-visit-user';
import {UserPayment} from '../modules/admin/models/userPayment';

@Injectable({
  providedIn: 'root'
})
export class HandlerService {

  getAnswerPrioriry(task: TaskAnswer): number {
    switch (task.id) {
      case 0:
        return 5;
      case 1:
        return 100 + +task.factor;
      case 2:
        return 9999;
      case 3:
        return 4;
      case 4:
        return 5;
    }
  }

  constructor(private defaultDataService: DefaultDataService) {
  }

  handleUserData(data: any): User {
    const newUser: User = {};
    newUser.id = data.id;
    newUser.nickname = data.username;
    newUser.isAdmin = this.hasUserRole('ROLE_ADMIN', data.roles);
    newUser.isTrial = this.hasUserRole('ROLE_TRIAL', data.roles);
    newUser.isPremium = this.hasUserRole('ROLE_PREMIUM', data.roles);
    newUser.email = data.email;
    newUser.language = data.language;
    newUser.partner = data.partner;
    newUser.coach = data.coach;
    newUser.subTo = data.subscription !== null ? new Date(data.subscription.date) : null;
    newUser.settings = this.handleUserSettings(data.settings);
    newUser.stackSize = data.stackDepth === null
      ? this.defaultDataService.getStackSizeById(0)
      : this.defaultDataService.getStackSizeById(data.stackDepth);

    newUser.stackSize.stackMin = data.stackMin ? data.stackMin : null;
    newUser.stackSize.stackMax = data.stackMax ? data.stackMax : null;

    newUser.activeChartGroup = data.activeChartGroup;
    newUser.chartGroups = data.chartGroups.map(group => this.handleChartPackData(group));
    newUser.hotKeys = data.hotkeys.map(hotKey => {
      const newHotKey = this.defaultDataService.getHotKeyByAction(hotKey.action);
      newHotKey.key = hotKey.key;
      newHotKey.keyCode = hotKey.keyCode;
      return newHotKey;
    });
    return newUser;
  }

  handleUserDataForAdmin(data: any): User {
    const newUser: User = {};
    newUser.id = data.id;
    newUser.email = data.email;
    newUser.referral = data.ref;
    newUser.registrationDate = data.date !== null ? new Date(data.date.date) : null;
    if (newUser.registrationDate !== null) {
      newUser.registrationDate.setTime(newUser.registrationDate.getTime() + +data.date.timezone_type * 60 * 60 * 1000);
    }
    newUser.subTo = data.subscription !== null ? new Date(data.subscription.date) : null;
    newUser.nickname = data.username;
    newUser.hands = data.countHands ? data.countHands : 0;
    newUser.countPayments = data.countPayments ? data.countPayments : 0;
    newUser.sumPayments = data.sumPayments ? data.sumPayments : 0;
    newUser.lastVisit = data.lastVisit ? new Date(data.lastVisit.date) : null;
    newUser.payments = data.payments ? data.payments.map((payment) => this.handleUserPayment(payment)) : [];
    newUser.totalPacksOwn = data.totalPacksOwn ? data.totalPacksOwn : 0;
    newUser.totalPacksPaid = data.totalPacksPaid ? data.totalPacksPaid : 0;

    return newUser;
  }

  hasUserRole(role: string, rolesList: string[]): boolean {
    return rolesList.indexOf(role) !== -1;
  }

  handleUserSettings(data: any): UserSettings {
    const settings: UserSettings = {};
    settings.roundTo = data.round;
    settings.limit = data.limit;
    settings.limit = data.limit;
    settings.opponents = data.opponents ? data.opponents : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    settings.isEffectiveStackShown = data.showEffective;
    settings.handsRange = this.handleHandsRange(data.handRange);
    settings.stackView = this.defaultDataService.getSTackViewById(data.stackView);
    settings.randomizer = data.random;
    return settings;
  }

  handleHandsRange(data: any): HandsRange {
    const newHandsRange: HandsRange = {};
    newHandsRange.id = data.id;
    newHandsRange.title = data.title;
    newHandsRange.isEditable = data.isEditable;
    newHandsRange.combinations = this.defaultDataService.getDefaultCombinations();
    if (data.combinations) {
      newHandsRange.combinations = newHandsRange.combinations.map((combination: Combination) => {
        if (data.combinations.indexOf(combination.id) !== -1) {
          combination.isFilled = true;
        }
        return combination;
      });
    }
    return newHandsRange;
  }

  handlePurchaseDataForAdmin(data: any): Purchase {
    const newPurchase: Purchase = {};
    newPurchase.date = data.date !== null ? new Date(data.date.date) : null;
    if (newPurchase.date !== null) {
      newPurchase.date.setTime(newPurchase.date.getTime() + +data.date.timezone_type * 60 * 60 * 1000);
    }
    newPurchase.id = data.id;
    newPurchase.email = data.email;
    newPurchase.promocode = data.promocode ? data.promocode : '';
    newPurchase.comment = data.comment ? data.comment : '';
    newPurchase.paymentMethod = this.defaultDataService.getPaymentMethodById(data.paymentId);
    newPurchase.sum = data.sum;
    newPurchase.author = data.author ? data.author : '';
    newPurchase.product = this.defaultDataService.getProductByTitle(data.productId);
    return newPurchase;
  }

  handlePurchaseSummary(data: any): PurchaseSummary {
    const summary = {} as PurchaseSummary;

    summary.fastSpring = {sum: data.fastspring.sum, count: data.fastspring.count};
    summary.gipsy = {sum: data.gipsy.sum, count: data.gipsy.count};
    summary.manual = {sum: data.manual.sum, count: data.manual.count};
    return summary;
  }

  handleLessonData(data: any): Lesson {
    const lesson: Lesson = {} as Lesson;

    lesson.id = data.id;
    lesson.title = data.title;
    lesson.tasks = data.tasks.map(task => this.handleTaskData(task));
    return lesson;
  }

  handleTaskData(data: any): Task {
    const task: Task = {} as Task;

    task.pot = data.pot;
    task.randomNumber = data.randomAnswerFlag;
    task.answers = data.answers.map(answer => this.handleTaskAnswerData(answer));
    task.answers = this.sortAnswersByPriority(task.answers).reverse();

    const mainAnswers = [
      task.answers[0],
      task.answers[1],
      task.answers[2] ? task.answers[2] : null,
    ];


    task.alternativeAnswers = task.answers.filter((answer) => {
      return !mainAnswers.includes(answer) || answer.id === 1;
    });

    task.isAllInAvailable = false;
    task.answers.forEach((answer: TaskAnswer) => {
      if (answer.id === 2) {
        task.isAllInAvailable = true;
      }
    });

    task.answers = mainAnswers;

    task.hero = this.handlePlayerData(data.hero);
    task.opponent1 = this.handlePlayerData(data.opponent1);
    task.opponent2 = this.handlePlayerData(data.opponent2);
    task.opponent3 = this.handlePlayerData(data.opponent3);
    task.opponent4 = this.handlePlayerData(data.opponent4);
    task.opponent5 = this.handlePlayerData(data.opponent5);
    task.lessonId = data.lessonId ? data.lessonId : null;
    task.rangeId = data.blindId ? data.blindId : null;
    task.secondAction = data.secondAction !== null ? data.secondAction : null;
    task.secondActionFactor = data.secondActionFactor ? data.secondActionFactor : null;
    task.bigBlind = data.bigBlind;
    task.effectiveStack = data.effectStack;
    task.handId = data.numberMistake;
    task.id = data.id;
    return task;
  }

  handleMistakeTaskData(data: any): Task {
    const newTask: Task = data.hand;
    newTask.answers = data.answers.map((value: any) => this.handleTaskAnswerData(value));
    newTask.handId = data.id;
    return newTask;
  }

  handleTaskAnswerData(data: any): TaskAnswer {
    const answer: TaskAnswer = {} as TaskAnswer;
    answer.id = data.id;
    answer.title = data.title;
    answer.isCorrect = data.isCorrect;
    answer.amount = data.bet;
    answer.factor = data.factor;
    return answer;
  }

  handleMistakesInfo(data: any): MistakesInfo {
    const info: MistakesInfo = {} as MistakesInfo;
    info.currentPage = +data.numberPage;
    info.pagesCount = +data.countPages;
    info.chartTitle = data.title;
    info.chartId = data.taskId;
    info.gameType = this.defaultDataService.getChartGameTypeById(data.playersCount);
    info.action = this.defaultDataService.getChartActionById(data.action);
    info.opponentsType = this.defaultDataService.getOpponentsTypeById(data.opponentsType);

    info.isFirstPage = info.currentPage === 1
      || info.currentPage === 2
      || (info.currentPage === 3 && info.pagesCount === 3);

    info.isLastPage = info.currentPage === info.pagesCount
      || info.currentPage + 1 === info.pagesCount
      || (info.currentPage + 2 === info.pagesCount && info.pagesCount === 3);

    info.pageArray = this.getArrayOfPage(info.pagesCount, info.currentPage);

    return info;
  }

  handleUserTableInfo(data: any): UserTableInfo {
    const info: UserTableInfo = {} as UserTableInfo;
    info.currentPage = +data.numberPage;
    info.pagesCount = +data.countPages;

    info.isFirstPage = info.currentPage === 1
      || info.currentPage === 2
      || (info.currentPage === 3 && info.pagesCount === 3);

    info.isLastPage = info.currentPage === info.pagesCount
      || info.currentPage + 1 === info.pagesCount
      || (info.currentPage + 2 === info.pagesCount && info.pagesCount === 3);

    info.pageArray = this.getArrayOfPage(info.pagesCount, info.currentPage);

    return info;
  }

  handlePurchaseTableInfo(data: any): PurchaseTableInfo {
    const info: PurchaseTableInfo = {} as PurchaseTableInfo;
    info.currentPage = +data.numberPage;
    info.pagesCount = +data.countPages;

    info.isFirstPage = info.currentPage === 1
      || info.currentPage === 2
      || (info.currentPage === 3 && info.pagesCount === 3);

    info.isLastPage = info.currentPage === info.pagesCount
      || info.currentPage + 1 === info.pagesCount
      || (info.currentPage + 2 === info.pagesCount && info.pagesCount === 3);

    info.pageArray = this.getArrayOfPage(info.pagesCount, info.currentPage);

    return info;
  }

  handleUserSummary(data: any): UsersSummary {
    const summary: UsersSummary = {} as UsersSummary;
    summary.usersCount = +data.count;
    summary.premiumCount = +data.countPremium;

    return summary;
  }

  getArrayOfPage(pageCount: number, currentPage: number): number [] {

    let pageArray: number [] = [];

    if (pageCount > 0) {
      for (let i = 1; i <= pageCount; i++) {
        pageArray.unshift(i);
      }
    }

    const indexPage = pageArray.indexOf(currentPage);

    if (currentPage === pageCount) {

      pageArray = pageArray.slice(0, indexPage + 3);

    } else if (currentPage === 1 && pageCount > 2) {

      pageArray = pageArray.slice(indexPage - 2, indexPage + 1);

    } else {

      pageArray = pageArray.slice(indexPage - 1, indexPage + 2);

    }

    return pageArray;
  }

  handlePlayerData(data: any): Player {
    const player: Player = {} as Player;

    player.nickname = data.nickName;
    switch (data.position) {
      case 0:
        player.position = 'EP';
        break;
      case 1:
        player.position = 'MP';
        break;
      case 2:
        player.position = 'CO';
        break;
      case 3:
        player.position = 'BTN';
        player.isDealer = true;
        break;
      case 4:
        player.position = 'SB';
        break;
      case 5:
        player.position = 'BB';
        break;
    }
    player.bet = data.bet;
    player.stack = data.stack;
    player.type = data.type;
    player.card1 = data.cards.length ? this.handleCardData(data.cards[0]) : null;
    player.card2 = data.cards.length ? this.handleCardData(data.cards[1]) : null;
    player.action = data.action !== null ? this.defaultDataService.getPlayerActionById(data.action) : null;
    player.icon = data.type;

    return player;
  }

  handleCardData(data: any): Card {
    const card: Card = {} as Card;
    card.id = data.id;
    card.dignity = data.dignity;
    switch (data.suit) {
      case 'spade':
        card.suit = 'spades';
        card.color = 'black';
        break;
      case 'heart':
        card.suit = 'hearts';
        card.color = 'red';
        break;
      case 'club':
        card.suit = 'clubs';
        card.color = 'green';
        break;
      case 'diamond':
        card.suit = 'diams';
        card.color = 'blue';
        break;
    }

    return card;
  }

  handleShortChartData(data: any): Chart {
    const chart: Chart = {} as Chart;

    chart.id = data.id;
    chart.gameType = this.defaultDataService.getChartGameTypeById(data.playersCount);
    chart.position = this.defaultDataService.getChartPositionById(data.position);
    chart.action = this.defaultDataService.getChartActionById(data.action);
    chart.title = data.title ? data.title : '';
    chart.description = data.description ? data.description.replace(new RegExp('\n', 'g'), '<br />') : '';
    chart.descriptionEn = data.descriptionEn ? data.descriptionEn.replace(new RegExp('\n', 'g'), '<br />') : '';
    chart.opponentsType = this.defaultDataService.getOpponentsTypeById(data.opponentsType);
    chart.isEditable = data.isEditable;
    chart.dynamicRaises = data.dynamicRaises ? data.dynamicRaises.map(raise => this.handleDynamicRaise(raise)) : [];
    chart.hands = data.countHands ? data.countHands : 0;
    chart.mistakes = data.countError ? data.countError : 0;
    chart.winRate = data.percent ? Math.round(data.percent) : 0;

    if (data.charts) {
      chart.charts = [];

      data.charts.forEach((dataChart: any) => {
        const newChart: CashChart = {};
        newChart.answers = dataChart.answers;
        newChart.id = dataChart.id;
        newChart.position = this.defaultDataService.getChartPositionById(dataChart.position);
        newChart.combinations = dataChart.combinations.substr(1).split('#');
        newChart.combinations = newChart.combinations.map((combination: string) => {
          return '#' + combination;
        });

        chart.charts.push(newChart);
      });
    }

    return chart;
  }

  handleDynamicRaise(data: any): {position: ChartPosition, amount: number} {
    return {
      position: this.defaultDataService.getChartPositionById(data.position),
      amount: data.amount
    };
  }

  handleAnswerData(data: any): Answer {
    const newAnswer: Answer = this.defaultDataService.getChartAnswerById(data.id);
    newAnswer.factor = data.factor;
    newAnswer.color = this.defaultDataService.getColorByKey(data.color);

    return newAnswer;
  }

  handleChartPackData(data: any): ChartPack {
    const pack: ChartPack = {};

    pack.id = data.id;
    pack.title = data.title;
    pack.uuid = data.uuid;

    return pack;
  }

  handleMixedChartData(data: any): MixedChart {
    const newChart: MixedChart = {};
    newChart.id = data.id;
    newChart.title = data.title;
    newChart.taskIds = data.charts;
    newChart.groupId = data.groupId;
    newChart.isEditable = data.isEditable;
    return newChart;
  }

  handleStaticRangeData(data: any): StaticRange {
    const newStaticRange: StaticRange = {};
    newStaticRange.id = data.id;
    newStaticRange.title = data.title;
    newStaticRange.position = this.defaultDataService.getChartPositionById(data.position);
    newStaticRange.answers = data.answers;
    newStaticRange.combinations = data.combinations.substr(1).split('#');
    newStaticRange.combinations = newStaticRange.combinations.map((combination: string) => {
      return '#' + combination;
    });

    return newStaticRange;
  }

  sortAnswersByPriority(answers: TaskAnswer[]): TaskAnswer[] {
    return answers.sort((a: TaskAnswer, b: TaskAnswer) => {
      return this.getAnswerPrioriry(b) - this.getAnswerPrioriry(a);
    });
  }

  // challenge

  handleChallengeData(data: any): Challenge {
    const newChallenge: Challenge = {};
    newChallenge.id = data.id;
    newChallenge.title = data.title;
    if (data.date) {
      newChallenge.date = new Date(data.date);
      newChallenge.isTest = false;
    } else {
      newChallenge.isTest = true;
    }
    newChallenge.taskCount = data.taskCount;
    newChallenge.availableIn = data.availableIn;
    newChallenge.rank = data.rank;
    newChallenge.bestResult = data.bestResult;
    newChallenge.players = data.players;
    newChallenge.isAvailable = data.isAvailable;
    newChallenge.result = data.result;
    return newChallenge;
  }

  handleChallengeLeaderBoard(data: any): ChallengeLeaderBoard {
    const newLeaderBoard: ChallengeLeaderBoard = {};
    newLeaderBoard.top = data.top.map((challengePlayer: any) => this.handleChallengePlayer(challengePlayer));
    newLeaderBoard.hero = this.handleChallengePlayer(data.hero);
    return newLeaderBoard;
  }

  handleChallengePlayer(data: any): ChallengePlayer {
    const player: ChallengePlayer = {};
    player.place = data.place;
    player.points = data.points;
    player.nickname = data.nickname;
    return player;
  }

  handleDashboardUsers(data: any): DashboardUsers {

    const dashboardUser: DashboardUsers = {};
    dashboardUser.lastMonth = {all: data.lastMonth.all, premium: data.lastMonth.premium};
    dashboardUser.currentMonth = {all: data.currentMonth.all, premium: data.currentMonth.premium};
    dashboardUser.allTime = {all: data.allTime.all, premium: data.allTime.premium};

    return dashboardUser;
  }

  handleDashboardVisits(data: any): DashboardVisitUser {

    const dashboardVisitUser: DashboardVisitUser = {};
    dashboardVisitUser.currentMonth = data.currentMonth
      ? {count: data.currentMonth.count, countPremium: data.currentMonth.countPremium}
      : {count: 0, countPremium: 0};
    dashboardVisitUser.lastMonth = data.lastMonth
      ? {count: data.lastMonth.count, countPremium: data.lastMonth.countPremium}
      : {count: 0, countPremium: 0};
    dashboardVisitUser.today = data.today
      ? {count: data.today.count, countPremium: data.today.countPremium}
      : {count: 0, countPremium: 0};

    return dashboardVisitUser;
  }

  handleDashboardCoaches(data: any): DashboardCoaches {
    const dashboardCoach: DashboardCoaches = {};
    dashboardCoach.lastMonth = {sum: data.lastMonth.sum, purchases: data.lastMonth.purchases};
    dashboardCoach.currentMonth = {sum: data.currentMonth.sum, purchases: data.currentMonth.purchases};
    dashboardCoach.allTime = {sum: data.allTime.sum, purchases: data.allTime.purchases};

    if (data.coachTitle) {
      dashboardCoach.coachTitle = data.coachTitle;
    }

    if (data.id) {
      dashboardCoach.id = data.id;
    }

    return dashboardCoach;
  }

  handleCoachPayment(data: any): CoachPayment {
    const coachPayment: CoachPayment = {};
    coachPayment.date = data.date !== null ? new Date(data.date.date) : null;
    if (coachPayment.date !== null) {
      coachPayment.date.setTime(coachPayment.date.getTime() + +data.date.timezone_type * 60 * 60 * 1000);
    }
    coachPayment.sum = data.sum ? data.sum : 0;
    coachPayment.method = this.defaultDataService.getPaymentMethodById(data.method);
    coachPayment.commission = data.commission ? data.commission : 0;
    coachPayment.affiliate = data.affiliate ? data.affiliate : 0;
    coachPayment.profit = data.profit ? data.profit : 0;

    return coachPayment;
  }

  handleUserPayment(data: any): UserPayment {
    const userPayment: UserPayment = {};

    userPayment.date = data.date !== null ? new Date(data.date.date) : null;
    if (userPayment.date !== null) {
      userPayment.date.setTime(userPayment.date.getTime() + +data.date.timezone_type * 60 * 60 * 1000);
    }
    userPayment.id = data.id ? data.id : null;
    userPayment.product = data.product ? data.product : '';
    userPayment.sum = data.sum ? data.sum : 0;
    userPayment.method = this.defaultDataService.getPaymentMethodById(data.system);
    userPayment.comment = data.comment ? data.comment : '';

    return userPayment;
  }

  handlePartnerPayment(data: any): PartnerPayment {
    const partnerPayment: PartnerPayment = {};

    partnerPayment.date = data.date !== null ? new Date(data.date.date) : null;
    if (partnerPayment.date !== null) {
      partnerPayment.date.setTime(partnerPayment.date.getTime() + +data.date.timezone_type * 60 * 60 * 1000);
    }
    partnerPayment.id = data.id ? data.id : null;
    partnerPayment.product = data.product ? data.product : '';
    partnerPayment.sum = data.sum ? data.sum : 0;
    partnerPayment.method = this.defaultDataService.getPaymentMethodById(data.method);
    partnerPayment.commission = data.commission ? data.commission : 0;
    partnerPayment.profit = data.profit ? data.profit : 0;

    return partnerPayment;
  }

  handlePartnerReferral(data: any): PartnerReferral {
    const partnerReferral: PartnerReferral = {};

    partnerReferral.date = data.date !== null ? new Date(data.date.date) : null;
    if (partnerReferral.date !== null) {
      partnerReferral.date.setTime(partnerReferral.date.getTime() + +data.date.timezone_type * 60 * 60 * 1000);
    }
    partnerReferral.id = data.id ? data.id : null;
    partnerReferral.email = data.email ? data.email : '';
    partnerReferral.method = data.method ? data.method : '';

    return partnerReferral;
  }

  handleDashboardCashFlow(data: any): DashboardCashFlow {
    const dashboardCashFlow: DashboardCashFlow = {};
    dashboardCashFlow.lastMonth = {sum: data.lastMonth.sum, purchases: data.lastMonth.purchases};
    dashboardCashFlow.currentMonth = {sum: data.currentMonth.sum, purchases: data.currentMonth.purchases};
    dashboardCashFlow.allTime = {sum: data.allTime.sum, purchases: data.allTime.purchases};

    return dashboardCashFlow;
  }

  handleDashboardPartners(data: any): DashboardPartners {
    const dashboardPartner: DashboardPartners = {};
    dashboardPartner.lastMonth = {referrals: data.lastMonth.referrals,
      purchases: data.lastMonth.purchases, profit: data.lastMonth.profit};
    dashboardPartner.currentMonth = {referrals: data.currentMonth.referrals,
      purchases: data.currentMonth.purchases, profit: data.currentMonth.profit};
    dashboardPartner.allTime = {referrals: data.allTime.referrals,
      purchases: data.allTime.purchases, profit: data.allTime.profit};

    if (data.id) {
      dashboardPartner.id = data.id;
    }

    if (data.partnerTitle) {
      dashboardPartner.partnerTitle = data.partnerTitle;
    }

    return dashboardPartner;
  }

  handleDashboardCommissions(data: any): DashboardCommission[] {
    const commissions: DashboardCommission[] = [];

    Object.keys(data.allTime).forEach((value: any, index: number) => {
      const newCommission: DashboardCommission = {
        system: this.defaultDataService.getPaymentMethodById(index),
        currentMonth: data.currentMonth[index],
        lastMonth: data.lastMonth[index],
        allTime: data.allTime[index]
      };
      commissions.push(newCommission);
    });

    return commissions;
  }
}
