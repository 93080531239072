import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, pluck, switchMap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandlerService} from './handler.service';
import {Lesson} from '../models/lesson';
import {CreatingLesson} from '../modules/charts/models/creatingLesson';
import {Chart} from '../models/chart/chart';
import {User} from '../models/user/user';
import {StaticRange} from '../models/chart/static-range';
import {StatsAnswer} from '../models/stats/stats-answer';
import {ChartPack} from '../models/chart/chart-pack';
import {Task} from '../models/task';
import {MistakesInfo} from '../models/stats/mistakes-info';
import {MixedChart} from '../models/chart/mixed-chart';
import {UserTableInfo} from '../models/user/user-table-info';
import {UsersSummary} from '../models/user/users-summary';
import {Purchase} from '../modules/admin/models/purchase';
import {PurchaseTableInfo} from '../modules/admin/models/purchaseTableInfo';
import {PurchaseSummary} from '../modules/admin/models/purchaseSummary';
import {Challenge} from '../models/challenge/challenge';
import {ChallengeLeaderBoard} from '../models/challenge/challenge-leader-board';
import {ChallengeAnswer} from '../models/challenge/challenge-answer';
import {ChallengeResult} from '../models/challenge/challenge-result';
import {DashboardUsers} from '../modules/admin/models/dashboardUsers';
import {DashboardCoaches, DashboardCoachStats} from '../modules/admin/models/dashboardCoaches';
import {DashboardCashFlow} from '../modules/admin/models/dashboardCashFlow';
import {DashboardPartners, DashboardPartnersStats} from '../modules/admin/models/dashboardPartners';
import {CoachPayment} from '../modules/admin/models/coachPayment';
import {PartnerPayment} from '../modules/admin/models/partnerPayment';
import {PartnerReferral} from '../modules/admin/models/partnerReferral';
import {CashChart} from '../models/chart/cash-chart';
import {HandsRange} from '../models/hands-range';
import {Combination} from '../models/combination';
import {DashboardCommission} from '../models/dashboardCommission';
// lodash
import * as _ from 'lodash';
import {DashboardVisitUser} from '../modules/admin/models/dashboard-visit-user';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  backendUrl = 'https://cash.back.preflophero.com/';
  token = '9dfaca8326cb0b63b66c69db81c8f58d080a625b1a1549c0d900664e60ef3c3af52bd45d60739ddbcf6b477a8661057974d2d51c146a949147269c77af29b042';

  constructor(private httpClient: HttpClient, private handlerService: HandlerService) { }

  getLessonById(id: number): Observable<Lesson> {
    return this.httpClient.get(this.backendUrl + 'getLesson?id=' + id).pipe(
      map(data => this.handlerService.handleLessonData(data))
    );
  }

  getMixedLessonById(id: number): Observable<Lesson> {
    return this.httpClient.get(this.backendUrl + 'getMixLesson?id=' + id).pipe(
      map(data => this.handlerService.handleLessonData(data))
    );
  }

  getDynamicMixedLessonById(ids: number[]): Observable<Lesson> {
    return this.httpClient.get(this.backendUrl + 'getMixLesson?taskIds=' + ids).pipe(
      map(data => this.handlerService.handleLessonData(data))
    );
  }

  createLesson(newLesson: CreatingLesson): Observable<any> {
    const lesson: any = _.cloneDeep(newLesson);
    newLesson.charts = lesson.charts.map((chart: CashChart) => {
      chart.combinations = chart.combinations.join('');
      chart.position = chart.position.id;
      return chart;
    });
    if (lesson.dynamicRaises) {
      for (const dynamicRaise of lesson.dynamicRaises) {
        dynamicRaise.position = dynamicRaise.position.id;
      }
    }
    return this.httpClient.post(this.backendUrl + 'saveTask', lesson);
  }

  createMixedLesson(newLesson: MixedChart): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'createMixLesson', newLesson);
  }

  clearHistory(lessonId: number): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'deleteHistory', {taskId: lessonId});
  }

  deletePack(packId: number): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'deleteGroupChart', {id: packId});
  }

  deleteLesson(lessonId: number): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'deleteChart', {id: lessonId});
  }

  deleteMixedLesson(lessonId: number): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'deleteMixTasks', {id: lessonId});
  }

  getHandsRanges(): Observable<HandsRange[]> {
    return this.httpClient.get(this.backendUrl + 'getHandsRanges').pipe(
      map((data: any) => data.map(range => this.handlerService.handleHandsRange(range)))
    );
  }

  saveHandsRange(range: HandsRange): Observable<any> {
    const newRange: any = {};
    newRange.id = range.id;
    newRange.title = range.title;
    newRange.combinations = range.combinations.filter(
      (combination: Combination) => combination.isFilled).map((combination: Combination) => combination.id);

    return this.httpClient.post(this.backendUrl + 'saveHandsRange', newRange);
  }

  getLessons(userId: number): Observable<{lessons: Lesson[], mixedLessons: Lesson[]}> {
    return this.httpClient.get(this.backendUrl + 'getTasks?userId=' + userId).pipe(
      switchMap((data: any) => {
        return of({lessons: data.tasks, mixedLessons: data.mixes});
      })
    );
  }

  getChartGroups(userId: number): Observable<ChartPack[]> {
    return this.httpClient.get(this.backendUrl + 'getChartGroups?userId=' + userId).pipe(
      map((data: any) => data.map(group => this.handlerService.handleChartPackData(group)))
    );
  }

  getStats(): Observable<Chart[]> {
    return this.httpClient.get(this.backendUrl + 'getStats').pipe(
      map((data: any) => data.taskStats.map((chart) => this.handlerService.handleShortChartData(chart)))
    );
  }

  getMistakes(chartId: number, handsCount: number = 10,
              elementId: number = null, numberPage: number = null): Observable<{tasks: Task[], info: MistakesInfo}> {
    return this.httpClient.get(
      this.backendUrl + 'getMistakes?taskId=' + chartId +
      '&elementId=' + elementId + '&count=' + handsCount + '&numberPage=' + numberPage).pipe(
      switchMap((data: any) => {
        return of(
          {tasks: data.mistakes.map((mistake) => this.handlerService.handleMistakeTaskData(mistake)),
              info: this.handlerService.handleMistakesInfo(data.other)});
      })
    );
  }

  getUsers(count: number = 10, elementId: number = null, numberPage: number = null,
           email: string = '', sortType?: number, sortTotal?: string, schoolId?: number):
    Observable<{ users: User[], info: UserTableInfo, summary: UsersSummary }> {
    return this.httpClient.get(
      this.backendUrl + 'getUsers?elementId=' + elementId
      + '&count=' + count + '&numberPage=' + numberPage + '&type=' + sortType + '&sortByTotal=' + sortTotal
      + '&email=' + email + '&schoolId=' + schoolId).pipe(
      switchMap((data: any) => {
        return of(
          {
            users: data.users.map((user) => this.handlerService.handleUserDataForAdmin(user)),
            info: this.handlerService.handleUserTableInfo(data.other),
            summary: this.handlerService.handleUserSummary(data.summary)
          });
      })
    );
  }

  getPurchases( count: number = 10, elementId: number = null, numberPage: number = null):
    Observable<{purchases: Purchase[], info: PurchaseTableInfo, summary: PurchaseSummary}> {

    return this.httpClient.get(
      this.backendUrl + 'getPurchases?elementId=' + elementId
      + '&count=' + count + '&numberPage=' + numberPage).pipe(
      switchMap((data: any) => {
        return of(
          {
            purchases: data.purchases.map((purchase) => this.handlerService.handlePurchaseDataForAdmin(purchase)),
            info: this.handlerService.handlePurchaseTableInfo(data.other),
            summary: this.handlerService.handlePurchaseSummary(data.summary)
          });
      })
    );
  }

  getDashboardStat():
    Observable<{users: DashboardUsers, coaches: DashboardCoaches, cashFlow: DashboardCashFlow,
      partners: DashboardPartners, commissions: DashboardCommission[], visits: DashboardVisitUser}> {

    return this.httpClient.get(
      this.backendUrl + 'getDashboardStat').pipe(
      switchMap((data: any) => {
        return of({
          users: this.handlerService.handleDashboardUsers(data.users),
          coaches: this.handlerService.handleDashboardCoaches(data.coaches),
          cashFlow: this.handlerService.handleDashboardCashFlow(data.cashFlow),
          partners: this.handlerService.handleDashboardPartners(data.partners),
          commissions: this.handlerService.handleDashboardCommissions(data.commissions),
          visits: this.handlerService.handleDashboardVisits(data.visits)
        });
      })
    );
  }

  getCoaches(): Observable<{coaches: DashboardCoaches[]}> {

    return this.httpClient.get(
      this.backendUrl + 'getDashboardCoaches').pipe(
      switchMap((data: {coaches: DashboardCoaches[]}) => {
        return of({
          coaches: data.coaches.map((coach) => this.handlerService.handleDashboardCoaches(coach))
        });
      })
    );
  }

  getCoachById(coachId: number): Observable<{coach: DashboardCoaches, payments: CoachPayment[]}> {

    return this.httpClient.get(
      `${this.backendUrl}getDashboardCoach?coach=${coachId}`).pipe(
      switchMap((data: {
        id: number,
        coachTitle: string,
        currentMonth: DashboardCoachStats,
        lastMonth: DashboardCoachStats,
        allTime: DashboardCoachStats,
        payments: CoachPayment[]}) => {
        const coachInfo = {
          id: data.id,
          coachTitle: data.coachTitle,
          currentMonth: data.currentMonth,
          lastMonth: data.lastMonth,
          allTime: data.allTime
        } as DashboardCoaches;
        return of({
          coach: this.handlerService.handleDashboardCoaches(coachInfo),
          payments: data.payments.map((payment) => this.handlerService.handleCoachPayment(payment))
        });
      })
    );
  }

  getPartners(): Observable<{partners: DashboardPartners[]}> {

    return this.httpClient.get(
      this.backendUrl + 'getDashboardPartners').pipe(
      switchMap((data: {partners: DashboardPartners[]}) => {
        return of({
          partners: data.partners.map((partner) => this.handlerService.handleDashboardPartners(partner))
        });
      })
    );
  }

  getPartnerById(partnerId: number):
    Observable<{partner: DashboardPartners, payments: PartnerPayment[], referrals: PartnerReferral[]}> {

    return this.httpClient.get(
      `${this.backendUrl}getDashboardPartner?partner=${partnerId}`).pipe(
      switchMap((data: {
        id: number,
        partnerTitle: string,
        currentMonth: DashboardPartnersStats,
        lastMonth: DashboardPartnersStats,
        allTime: DashboardPartnersStats,
        payments: CoachPayment[],
        referrals: PartnerReferral[]}) => {
        const partnerInfo = {
          id: data.id,
          partnerTitle: data.partnerTitle,
          currentMonth: data.currentMonth,
          lastMonth: data.lastMonth,
          allTime: data.allTime
        } as DashboardPartners;
        return of({
          partner: this.handlerService.handleDashboardPartners(partnerInfo),
          payments: data.payments.map((payment) => this.handlerService.handlePartnerPayment(payment)),
          referrals: data.referrals.map((referral) => this.handlerService.handlePartnerReferral(referral))
        });
      })
    );
  }

  addPurchase(purchase: Purchase): Observable<Purchase> {

    const postPurchase = {
      date: purchase.date,
      email: purchase.email,
      paymentId: purchase.paymentMethod.id,
      sum: purchase.sum,
      productId: purchase.product.title,
      promocode: purchase.promocode,
      comment: purchase.comment,
    };

    return this.httpClient.post(this.backendUrl + 'newManualPurchase', postPurchase).pipe(
      map((purchases: any) => this.handlerService.handlePurchaseDataForAdmin(purchases.data))
    );
  }

  getChartGroup(groupId: number, userId: number): Observable<{charts: Chart[], mixCharts: Chart[],
    uuid?: string, isEditable?: boolean}> {
    return this.httpClient.get(this.backendUrl + 'getChartGroup?userId=' + userId + '&id=' + groupId).pipe(
      switchMap((data: any) => {
        return of(
          {charts: data.charts.map((chart) => this.handlerService.handleShortChartData(chart)),
            mixCharts: data.mixCharts.map((chart) => this.handlerService.handleShortChartData(chart)),
            uuid: data.uuid,
            isEditable: data.isEditable,
            title: data.title});
      })
    );
  }

  createChartGroup(chartPack): Observable<ChartPack> {
    return this.httpClient.post(this.backendUrl + 'createChartGroup', chartPack).pipe(
      map(data => this.handlerService.handleChartPackData(data))
    );
  }

  getCharts(): Observable<{charts: Chart[], mixCharts: Chart[]}> {
    return this.httpClient.get(this.backendUrl + 'getCharts').pipe(
      switchMap((data: any) => {
        return of(
          {charts: data.charts.map((chart) => this.handlerService.handleShortChartData(chart)),
              mixCharts: data.mixCharts.map((chart) => this.handlerService.handleShortChartData(chart))});
      })
    );
  }

  getChartById(id: number): Observable<Chart> {
    return this.httpClient.get(this.backendUrl + 'getChart?id=' + id).pipe(
      pluck('chart'),
      map((data: any) => this.handlerService.handleShortChartData(data))
    );
  }

  getMixedChartById(id: number): Observable<MixedChart> {
    return this.httpClient.get(this.backendUrl + 'getMixedChart?id=' + id).pipe(
      map((data: any) => this.handlerService.handleMixedChartData(data))
    );
  }

  getChallenges(): Observable<{challenges: Array<Challenge>, leaderBoard: ChallengeLeaderBoard}> {
    return this.httpClient.get(this.backendUrl + 'getChallenges').pipe(
      switchMap((data: any) => {
        return of(
          {challenges: data.challenges.map((challenge) => this.handlerService.handleChallengeData(challenge)),
            leaderBoard: this.handlerService.handleChallengeLeaderBoard(data.leaderboard)});
      })
    );
  }

  getChallenge(id: string = null, test: number = 0): Observable<{isAvailable: boolean, tasksLeft: number, tasks: Task[]}> {
    return this.httpClient.get(this.backendUrl + 'getChallenge?id=' + id + '&test=' + test).pipe(
      switchMap((data: any) => {
        const newTasks = data.tasks.map((task: any) => {
          const newTask = this.handlerService.handleTaskData(task.data);
          newTask.id = task.id;
          return newTask;
        });
        return of({isAvailable: data.isAvailable, tasksLeft: data.taskLeft, tasks: newTasks});
      })
    );
  }

  getChallengeResult(test: number = 0): Observable<ChallengeResult> {
    return this.httpClient.get(this.backendUrl + 'getChallengeResult?test=' + test).pipe(
      switchMap((data: any) => {
        return of({title: data.title, isComplete: !data.isAvailable, correct: data.correct, all: data.all});
      })
    );
  }

  checkInvite(): Observable<{isExist: boolean, user: User}> {
    return this.httpClient.get(this.backendUrl + 'auth').pipe(
      switchMap((data: any) => {
        if (data.isExist) {
          return of({isExist: true, user: this.handlerService.handleUserData(data) });
        } else {
          return of({isExist: false, user: null});
        }
      })
    );
  }

  checkAuth(code: string, ref: string): Observable<{isExist: boolean, user: User, token: string}> {
    return this.httpClient.post(this.backendUrl + 'connect/google/check', {code, ref}).pipe(
      switchMap((data: any) => {
        if (data.isExist) {
          return of({isExist: true, user: this.handlerService.handleUserData(data.profile), token: data.token});
        } else {
          return of({isExist: false, user: null, token: null});
        }
      })
    );
  }

  getRangeById(rangeId: number): Observable<StaticRange>  {
    return this.httpClient.get(this.backendUrl + 'getRange?id=' + rangeId).pipe(
      map(data => this.handlerService.handleStaticRangeData(data))
    );
  }

  updateUser(user: User): Observable<any> {
    const updatedUser = {
      id: user.id,
      round: user.settings.roundTo,
      isMailing: true,
      showEffective: user.settings.isEffectiveStackShown,
      stackView: user.settings.stackView.id,
      limit: user.settings.limit,
      language: user.language,
      random: user.settings.randomizer,
      username: user.nickname,
      groupId: user.activeChartGroup.id,
      stackDepth: user.stackSize.id,
      stackMin: user.stackSize.stackMin,
      stackMax: user.stackSize.stackMax,
      hotkeys: user.hotKeys,
      opponents: user.settings.opponents
    };

    return this.httpClient.post(this.backendUrl + 'saveUser', updatedUser);
  }

  saveStatsAnswer(answer: StatsAnswer): Observable<any> {
    return this.httpClient.post(this.backendUrl + 'setAnswer', answer);
  }

  saveChallengeAnswer(answer: ChallengeAnswer): Observable<any> {
    return this.httpClient.post(this.backendUrl + 'setAnswerChallenge', answer);
  }

  registerPurchase(purchase: Purchase) {
    const headers = new HttpHeaders({
      'X-AUTH-TOKEN': this.token,
    });
    const postPurchase = {
      date: purchase.date,
      email: purchase.email,
      paymentId: purchase.paymentMethod.id,
      sum: purchase.sum,
      productId: purchase.product.title,
      promocode: purchase.promocode,
      comment: purchase.comment,
    };

    return this.httpClient.post(this.backendUrl + 'newManualPurchase', postPurchase, { headers });
  }
}
