import {PokerHeroProduct} from './paypal-buy-dialog.model';

export const PRODUCTS: PokerHeroProduct[] = [
  {
    id: 'ph-cash-sub-1',
    title: '1 Month Premium',
    description: '',
    cost: 20,
  },
  {
    id: 'ph-cash-sub-3',
    title: '3 Months Premium',
    description: '',
    cost: 45,
  },
  {
    id: 'ph-cash-sub-12',
    title: '12 Months Premium',
    description: '',
    cost: 150,
  },
  {
    id: 'ph-cash-chart-1',
    title: 'GTO pack',
    description: '',
    cost: 199,
  },
  {
    id: 'dyrdom-pack',
    title: 'Dyrdom1 pack',
    description: '',
    cost: 99,
  },
];
