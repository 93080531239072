import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
// models
import {Task} from '../../../models/task';
import {MistakesInfo} from '../../../models/stats/mistakes-info';
import {HandReplayDialogComponent} from '../../modals/hand-replay-dialog/hand-replay-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-stats-table',
  templateUrl: './stats-table.component.html',
  styleUrls: ['./stats-table.component.scss']
})
export class StatsTableComponent implements OnInit {

  @Input() hands: Task[];
  @Input() info: MistakesInfo;

  @Output() pageNumber = new EventEmitter<number>();

  constructor(private dialog: MatDialog) { }

  clickToSwitchPage(pageNumber: number) {

    if (pageNumber !== this.info.currentPage) {
      this.pageNumber.emit(pageNumber);

    }

  }

  ngOnInit() {
  }

  showHand(clickedTask: Task) {

    this.dialog.open(HandReplayDialogComponent, {
      data: {
        indexHistoryItem: this.hands.indexOf(clickedTask),
        lengthHistoryItems: this.hands.length,
        isClickReplay: true,

        tasks: this.hands,
      },
      autoFocus: false,
      panelClass: 'app-full-screen__hand-replay',
    });
  }

  showChart(event: MouseEvent, clickedTask: Task) {
    event.stopPropagation();
    this.dialog.open(HandReplayDialogComponent, {
      data: {
        indexHistoryItem: this.hands.indexOf(clickedTask),
        lengthHistoryItems: this.hands.length,
        isClickReplay: false,

        tasks: this.hands,
      },
      autoFocus: false,
      panelClass: 'app-full-screen__hand-replay',
    });
  }
}
