import {Injectable} from '@angular/core';
import {SplitAnswer} from '../../modules/charts/models/split-answer';
import {Answer} from '../../modules/charts/models/answer';
import * as _ from 'lodash';
import {SnackbarService} from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  savedChart: {combinations?: string[], answers?: Answer[], splitAnswers?: SplitAnswer[]} = undefined;

  constructor(private snackbarService: SnackbarService) {
  }

  saveChart(combinations: string[], answers: Answer[], splitAnswers: SplitAnswer[]) {

    this.savedChart = {};
    this.savedChart.combinations = _.cloneDeep(combinations);
    this.savedChart.answers = _.cloneDeep(answers);
    this.savedChart.splitAnswers = _.cloneDeep(splitAnswers);
    this.snackbarService.showMessage('Chart is saved in buffer');
  }

  getSavedChart(): {combinations?: string[], answers?: Answer[], splitAnswers?: SplitAnswer[]} {

    return _.cloneDeep(this.savedChart);
  }

}
