import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Answer} from '../../../modules/charts/models/answer';
import {Combination} from '../../../models/combination';
import * as _ from 'lodash';
import {filter} from 'rxjs/operators';
import {AnswerCreatingDialogComponent} from '../../modals/answer-creating-dialog/answer-creating-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AppService} from '../../../services/app.service';
import {ActionColors, DefaultDataService} from '../../../services/default-data.service';
import {ChartColor} from '../../../models/chart-color';
import {SplitAnswerCreatingDialogComponent} from '../../modals/split-answer-creating-dialog/split-answer-creating-dialog.component';
import {SplitAnswer} from '../../../modules/charts/models/split-answer';
import {ChartService} from '../../services/chart.service';
import {SnackbarService} from '../../services/snackbar.service';
import {ImportDialogComponent} from '../../modals/import-dialog/import-dialog.component';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit {

  @Input() combinations: string[];
  @Input() parentCombinations: Combination[];
  @Input() previousRangeCombinations: Combination[];
  @Input() previousRangeAnswers: Answer[];
  @Input() answers: Answer[];
  @Input() isDisabled = false;
  @Input() actionTitle = '';
  @Input() canBeDeleted = false;
  @Output() chartDeleted = new EventEmitter<any>();

  activeAnswer: Answer | SplitAnswer;
  splitAnswers: Array<SplitAnswer> = [];

  constructor(private dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef, private appService: AppService,
              private defaultDataService: DefaultDataService, private chartService: ChartService,
              private snackbarService: SnackbarService) {
  }

  ngOnInit() {
    this.activeAnswer = this.answers[0];

    this.generateSplitAnswers();
  }

  generateSplitAnswers() {
    this.splitAnswers = [];
    this.combinations.forEach((combination: string) => {
      const parts = combination.split(',');
      if (parts[1] === 'y' && !this.isSplitAnswerExist(parts[2])) {
        const newSplitAnswer: SplitAnswer = {
          parts: [],
          title: '',
          value: parts[2],
          isRandom: 'y'
        };
        const combinationParts: Array<{width?: number}> = [];
        const answers = parts[2].split('-');
        let currentWeight = 0;
        answers.forEach((answer: string, i: number) => {
          let symbol;
          let answerWidth;
          if (i === 0) {
            symbol = answer[2] === '(' ? answer.substr(0, 2) : answer[0];
            const helpIndex = answer.indexOf(')');
            answerWidth = +answer.substr(symbol.length + 1, helpIndex - symbol.length - 1);
            currentWeight = answerWidth;
          }
          if (i !== 0 && i !== answers.length - 1) {
            symbol = answer[2] === '(' ? answer.substr(0, 2) : answer[0];
            const helpIndex = answer.indexOf(')');
            answerWidth = +answer.substr(symbol.length + 1, helpIndex - symbol.length - 1)
              - currentWeight;
            currentWeight += answerWidth;
          }
          if (i === answers.length - 1) {
            symbol = answer;
            answerWidth = 100 - combinationParts.reduce((sum, combinationPart) => sum + combinationPart.width, 0);
          }
          const newAnswer = this.findAnswerBySymbol(symbol);
          combinationParts.push({width: answerWidth});

          newSplitAnswer.parts.push({answer: newAnswer, weight: answerWidth});
          newSplitAnswer.title += `${answerWidth.toString()}% ${newAnswer.title} `;
        });
        this.splitAnswers.push(newSplitAnswer);
      }

    });
  }

  isSplitAnswerExist(value: string): boolean {
    return this.splitAnswers.findIndex((answer: SplitAnswer) => answer.value === value) !== -1;
  }

  findAnswerBySymbol(symbol): Answer {
    switch (symbol) {
      case 'c':
      case 'f':
      case 'a':
      case 'ch':
        return this.answers.find((answer: Answer) => answer.value === symbol);
      case 'r':
        return this.answers.find((answer: Answer) => answer.color.value === ActionColors.RAISE);
      case 'r2':
        return this.answers.find((answer: Answer) => answer.color.value === ActionColors.RAISE2);
      case 'r3':
        return this.answers.find((answer: Answer) => answer.color.value === ActionColors.RAISE3);

    }
  }

  onCombinationClick(combinationIndex: number) {
    const parts = this.combinations[combinationIndex].split(',');
    if (parts[1] === this.activeAnswer.isRandom && parts[2] === this.activeAnswer.value) {
      this.combinations[combinationIndex] = parts[0];
    } else {
      parts[1] = this.activeAnswer.isRandom;
      parts[2] = this.activeAnswer.value;
      this.combinations[combinationIndex] = parts.join(',');
    }
  }

  onMouseOver(combinationIndex: number) {
    if (this.appService.isMouseDown$.value) {

      this.fillAsActive(combinationIndex);
    }
  }

  fillAsActive(combinationIndex: number) {
    const parts = this.combinations[combinationIndex].split(',');
    if (!parts[1] && !parts[2]) {
      parts[1] = this.activeAnswer.isRandom;
      parts[2] = this.activeAnswer.value;
      this.combinations[combinationIndex] = parts.join(',');
    }
  }

  deleteAnswer(answer: Answer, event) {
    event.stopPropagation();
    const index = this.answers.indexOf(answer);
    this.answers.splice(index, 1);
    this.combinations.forEach((combination: string, i: number) => {
      const parts = combination.split(',');
      if (parts[2] === answer.value) {
        this.combinations[i] = parts[0];
      }
      if (parts[1] === 'y') {
        this.combinations[i] = parts[0];
      }
    });
    if (answer.value === 'r') {
      this.answers.forEach((selectedAnswer: Answer, i: number) => {
        if (selectedAnswer.value === 'r2') {
          this.answers[i].value = 'r';
          this.answers[i].color.value = this.defaultDataService.getColorByCombinationSymbol('r');
        }
        if (selectedAnswer.value === 'r3') {
          this.answers[i].value = 'r2';
          this.answers[i].color.value = this.defaultDataService.getColorByCombinationSymbol('r2');
        }
      });
      this.combinations.forEach((combination: string, i: number) => {
        const parts = combination.split(',');
        if (parts[2] === 'r2') {
          parts[2] = 'r';
          this.combinations[i] = parts.join(',');
        }
        if (parts[2] === 'r3') {
          parts[2] = 'r2';
          this.combinations[i] = parts.join(',');
        }
      });
    }
    if (answer.value === 'r2') {
      this.answers.forEach((selectedAnswer: Answer, i: number) => {
        if (selectedAnswer.value === 'r3') {
          this.answers[i].value = 'r2';
          this.answers[i].color.value = this.defaultDataService.getColorByCombinationSymbol('r2');
        }
      });
      this.combinations.forEach((combination: string, i: number) => {
        const parts = combination.split(',');
        if (parts[2] === 'r3') {
          parts[2] = 'r2';
          this.combinations[i] = parts.join(',');
        }
      });
    }
    this.activeAnswer = _.cloneDeep(this.answers[0]);
  }

  onAnswerClick(answer: Answer | SplitAnswer) {
    this.activeAnswer = _.cloneDeep(answer);
  }

  openAnswerCreatingDialog() {
    const answers: Answer[] = [];
    const raisedAnswers = this.answers.filter((answer: Answer) => answer.id === 1);
    if (raisedAnswers.length < 3) {
      answers.push({id: 1, title: 'Raise', color: this.getAvailableRaiseColor(raisedAnswers)});
    }

    if (this.answers.filter((answer: Answer) => answer.id === 2).length === 0) {
      answers.push({id: 2, title: 'All-in', color: this.defaultDataService.getColorByKey('all-in')});
    }

    const dialogRef = this.dialog.open(AnswerCreatingDialogComponent, {
      data: answers,
      autoFocus: false
    });

    dialogRef.afterClosed().pipe(
      filter(value => value)
    ).subscribe((answer: Answer) => {
      answer.isRandom = 'n';
      if (answer.id === 1) {
        let newRaiseOrder = 0;
        raisedAnswers.forEach((raisedAnswer: Answer) => {
          if (answer.factor < raisedAnswer.factor) {
            this.increaseRaiseOrder(raisedAnswer.factor);
          } else {
            newRaiseOrder++;
          }
        });
        answer.value = this.getRaiseSymbolByOrder(newRaiseOrder);
        answer.color.value = this.defaultDataService.getColorByCombinationSymbol(answer.value);
        this.answers.push(answer);
      } else {
        answer.value = this.defaultDataService.getSymbolByActionId(answer.id);
        this.answers.push(answer);
      }
      this.answers = this.answers.sort((a: Answer, b: Answer) => {
        return a.factor - b.factor;
      });
      this.changeDetectorRef.detectChanges();
    });
  }

  openSplitAnswerCreatingDialog() {

    const dialogRef = this.dialog.open(SplitAnswerCreatingDialogComponent, {
      data: this.answers,
      autoFocus: false
    });

    dialogRef.afterClosed().pipe(
      filter(value => value)
    ).subscribe((newAnswer: SplitAnswer) => {
      this.splitAnswers.push(newAnswer);
      this.changeDetectorRef.detectChanges();
    });
  }

  increaseRaiseOrder(factor: number) {
    const index = this.answers.findIndex((answer: Answer) => answer.factor === factor);
    const newValue = this.answers[index].value === 'r' ? 'r2' : 'r3';
    this.combinations.forEach((combination: string, i: number) => {
      const parts = combination.split(',');
      if (parts[1] === 'n' && parts[2] === this.answers[index].value) {
        parts[2] = newValue;
        this.combinations[i] = parts.join(',');
      }
    });
    this.answers[index].value = newValue;
    this.answers[index].color.value = this.defaultDataService.getColorByCombinationSymbol(newValue);
  }

  getRaiseSymbolByOrder(order: number): string {
    switch (order) {
      case 0:
        return 'r';
      case 1:
        return 'r2';
      case 2:
        return 'r3';
    }
  }

  getAvailableRaiseColor(answers: Answer[]): ChartColor {
    const raisedColors = this.defaultDataService.getRaisedColors();
    let result: ChartColor = {};
    raisedColors.forEach((color: ChartColor) => {
      const isExist = answers.find((answer: Answer) => answer.color.key === color.key);
      if (!isExist) {
        result = color;
      }
    });
    return result;
  }

  fillEmptyAsSelected() {
    this.combinations.forEach((combination: string, index: number) => {
      this.fillAsActive(index);
    });
  }

  // useful chart action
  reset() {
    this.combinations.forEach((combination: string, index: number) => {
      this.combinations[index] = combination.split(',')[0];
    });
  }

  copy() {
    this.chartService.saveChart(this.combinations, this.answers, this.splitAnswers);
  }

  import() {
    const dialogRef = this.dialog.open(ImportDialogComponent, {
      data: this.answers,
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().pipe(
      filter(value => value)
    ).subscribe((combinations: Array<string>) => {
      combinations.forEach((comb, i) => {
        this.combinations[i] = comb;
      });
      this.generateSplitAnswers();
      this.changeDetectorRef.detectChanges();
    });
  }

  paste() {
    const savedChart = this.chartService.getSavedChart();
    if (!savedChart) {
      this.snackbarService.showMessage('No chart in buffer');
    } else {
      this.combinations.forEach((combination: string, index) => {
        this.combinations[index] = savedChart.combinations[index];
      });
      this.answers.splice(0, this.answers.length);
      savedChart.answers.forEach((answer: Answer) => this.answers.push(answer));
      this.splitAnswers.splice(0, this.splitAnswers.length);
      savedChart.splitAnswers.forEach((answer: Answer) => this.splitAnswers.push(answer));
    }
  }

  isDeletingAvailable(answer: Answer): boolean {
    return answer.id !== 0 && answer.id !== 3 && answer.id !== 4 && !this.parentCombinations;
  }
}
