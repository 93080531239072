import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {PaypalProductService} from "../../../services/paypal-product.service";

@Component({
  selector: 'app-buy-dialog',
  templateUrl: './buy-dialog.component.html',
  styleUrls: ['./buy-dialog.component.scss']
})
export class BuyDialogComponent implements OnInit {

  constructor(private productService: PaypalProductService,
              public dialogRef: MatDialogRef<BuyDialogComponent>) { }

  buy1Month() {
    this.productService.buyProduct('ph-cash-sub-1');
    this.close();
  }

  buy12Month() {
    this.productService.buyProduct('ph-cash-sub-12');
    this.close();
  }

  buy3Month() {
    this.productService.buyProduct('ph-cash-sub-3');
    this.close();
  }

  openDiscord() {
    window.open('https://discord.gg/pokerhero', '_blank');
  }

  openSkype() {
    window.open('https://join.skype.com/invite/hUXNncXpufCR', '_blank');
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
