import {Injectable} from '@angular/core';
import {ApiService} from '../services/api.service';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {StaticRange} from '../models/chart/static-range';

@Injectable({
  providedIn: 'root'
})
export class ChartStorage {

  charts: StaticRange[] = [];

  constructor(private apiService: ApiService) { }

  private findInStorage(rangeId: number): StaticRange {
    return this.charts.find((chart: StaticRange) => chart.id === rangeId);
  }

  private writeRange(range: StaticRange) {
    this.charts.push(range);
  }

  getRangeById(rangeId: number): Observable<StaticRange> {
    const range = this.findInStorage(rangeId);
    if (range) {
      return of(range);
    } else {
      return this.apiService.getRangeById(rangeId).pipe(
        tap((newRange: StaticRange) => this.writeRange(newRange))
      );
    }
  }


}
