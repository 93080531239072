import {Component, Input, OnInit} from '@angular/core';
import {Player} from '../../../models/player';

@Component({
  selector: 'app-player-hud',
  templateUrl: './player-hud.component.html',
  styleUrls: ['./player-hud.component.scss']
})
export class PlayerHudComponent implements OnInit {

  @Input() player: Player;

  layoutCard;

  constructor() { }

  ngOnInit() {

    this.layoutCard = localStorage.getItem('layoutCard');
  }

}
